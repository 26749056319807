<template>
  <div>
    <div class="flex justify-between">
      <h4 class="text-lg font-bold">{{outlets_name}}<span style="font-weight:400;"> 的商品货架</span></h4>
      <a-button v-if="hasAuthOnsale" class="ml-3 p-0" type="link" @click="handlerSyncStore">复制其他网点的货架</a-button>
    </div>

    <div>
      <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeTypeId">
        <a-tab-pane key="" tab="全部"></a-tab-pane>
        <a-tab-pane v-for="item in Object.keys(goodsType)"
                :value="item" :key="item" 
                :tab="goodsType[item]"></a-tab-pane>
      </a-tabs>
    </div>
    
    <base-table ref="saleGoods"
                id="sku_id"
                rowKey="sku_id"
                :columnsData="columns"
                :tableData="tableData"
                :rowClassName="setRowClass"
                :row-selection="rowSelection"
                :noPage="true"
                :addHeight="10"
                :total="total"
                :getListFunc="initData"
                :page.sync="page"
                :pageSize.sync="page_count">
      <template #toolbar>
        <div v-if="hasAuthOnsale || hasAuthStock" class="w-full mb-2 flex justify-between">
          <div slot="tabBarExtraContent" class="flex">
            <template v-if="hasAuthStock">
              <a-button v-if="!isInEditStock" 
                  :disabled="!activeTypeId || activeTypeId==3"
                  @click="handlerEditStock" key="edit">编辑库存</a-button>
              <a-button v-else 
                  @click="handlerCancelEdit" key="cancel-edit">退出编辑</a-button>
            </template>
          </div>
          <div slot="tabBarExtraContent">
            <a-button v-if="hasAuthOnsale" class="ml-3" type="primary" @click="handlerAddGoods">添加商品</a-button>
          </div>
        </div>
        <div v-if="isInEditStock" class="flex justify-between mt-1 mb-2">
          <a-button type="link" class="mr-2" size="small" style="padding:0;"
              :disabled="!(rowSelection && rowSelection.selectedRowKeys.length)"
              @click="handleMultiStock">批量修改库存</a-button>
          <a-button type="link" size="small" class="p-0 mr-3"
              @click="handlerSaveStocks">保存库存数据</a-button>
        </div>
      </template>

      <template #current_stock="{ text, record }">
        <a-input-number type="number" :min="0"
            v-if="isInEditStock && !record.is_cake" v-model="record.current_stock" />
        <span v-else-if="!record.is_cake">{{ text }}</span>
        <span v-else-if="record.is_cake">{{ text > 999 ? "999+" : text }}</span>
      </template>
      <template #max_stock="{ text, record }">
        <a-input-number type="number" :min="0"
            v-if="isInEditStock && !record.is_cake" v-model="record.max_stock" />
        <span v-else-if="!record.is_cake">{{ text }}</span>
        <span v-else-if="record.is_cake">{{ text > 999 ? "999+" : text }}</span>
      </template>
      <template #total_stock="{ text, record }">
        <!-- <a-input-number type="number" :min="0"
            v-if="isInEditStock && record.is_cake" v-model="record.total_stock" /> -->
        <span v-if="record.is_cake">{{ text==-1 ? "未设置" : text }}</span>
      </template>
    </base-table>

    <add-goods-modal v-if="isShowAddGoods"
                     :show.sync="isShowAddGoods"
                     :outletsId="outlets_id"
                     :typeId="activeTypeId"
                     :newKeys="newKeys"
                     @ok="handlerAddGoodsOK" />

    <async-outlets-modal v-if="isAsyncOutlets"
                         :outletsId="outlets_id"
                         :outletsName="outlets_name"
                         :onlyCake="outlets_type==2"
                         :show.sync="isAsyncOutlets" />

    <a-modal :visible.sync="isShowMultiStock"
             title="批量设置商品SKU的每日最大库存"
             destroyOnClose
             width="400px"
             @ok="handleMultiStockOk"
             @cancel="isShowMultiStock=false">
      <div class="flex items-center">
        <span>每日最大库存</span>
        <span class="ml-3"
              style="min-width:200px;">
          <a-input v-model="batchStock" placeholder="填写数字" />
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import AddGoodsModal from "./components/add-goods-modal.vue"
import AsyncOutletsModal from "./components/async-outlets-modal.vue"

import { goodsType, formatGoodsType } from "@/utils/type"
import { rowSpan } from "@/utils/index"
import { cutQuery } from '@/utils/ossImg'

import {
  getSaleGoodsList,
  editGoodsData,
  getSkuListByGoodsId,
} from "@/api/shop.js"
import { checkAuth } from "@/utils/authData";

export default {
  components: {
    AddGoodsModal,
    AsyncOutletsModal,
  },
  data () {
    return {
      goodsType,

      outlets_id: this.$route.query.outlets_id,
      outlets_type: this.$route.query.outlets_type,
      outlets_name: this.$route.query.outlets_name,

      hasAuthOnsale: checkAuth("regional-shop-sell:goods"), // 操作上下架
      hasAuthStock:  checkAuth("regional-shop-sell:stock"), // 操作库存

      batchStock: '',
      isShowMultiStock: false,
      columns: [],
      baseColumns: [
        {
          title: "分类",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          customRender: (text, row) => {
            return {
              children: formatGoodsType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",

          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.flavor_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "售价",
          dataIndex: "price",
          align: "center",
          width: 80,
          slots: {
            customRender: "price",
          },
          slotsType: "format",
          slotsFunc: (val) => ('￥'+val),
        },
        {
          title: "今日库存",
          dataIndex: "current_stock",
          align: "center",
          width: 100,
          slots: { customRender: "current_stock" },
        },
        {
          title: "每日库存",
          dataIndex: "max_stock",
          align: "center",
          width: 100,
          slots: { customRender: "max_stock" },
        },
        {
          title: "剩余总库存",
          dataIndex: "total_stock",
          align: "center",
          width: 100,
          slots: { customRender: "total_stock" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: (
                <a-button type="link"
                  onClick={() => {
                    this.handlerRemove(row)
                  }}
                >下架</a-button>
              ),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
      ],
      isInEditStock: false,
      isAsyncOutlets: false, // 是否展示同步网点数据
      isShowAddGoods: false,
      activeTypeId: "",
      delivery_limit: 0,
      goodsList: [],
      total: 0,
      typeSubList: [],
      tableData: [],
      selectKeys: [],
      newKeys: [],
      page: 1,
      page_count: 20,
      paramsData: {
        off_shelves_goods_data: [],
        update_sku_data: [],
      },
      rowSelection: null,
      baseRowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.sku_id);
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.sku_id
            );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.sku_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    }
  },
  mounted () {
    if(!this.hasAuthOnsale){
      this.baseColumns = this.baseColumns.filter(el=>!['operation'].includes(el.dataIndex))
    }

    this.handlerTypeChange(this.activeTypeId)
  },
  methods: {
    handlerTypeChange(type_id){
      if(type_id == 1 || type_id == 2){
        this.columns = this.baseColumns.filter(el=>!['total_stock'].includes(el.dataIndex))
      }else if(type_id == 3){
        this.columns = this.baseColumns.filter(el=>!['current_stock','max_stock'].includes(el.dataIndex))
      }else{
        this.columns = this.baseColumns
      }
      
      this.activeTypeId = type_id
      if(type_id == 3){ // 蛋糕
        this.handlerCancelEdit()
      }
      this.initData()
    },

    async initData (goods_type) {
      goods_type = goods_type || this.activeTypeId

      // 清空勾选内容
      if(this.rowSelection && this.rowSelection.selectedRowKeys){
        this.rowSelection.selectedRowKeys = []
      }
      // 清空编辑缓存
      this.paramsData.off_shelves_goods_data = []
      this.paramsData.update_sku_data = []

      const { data } = await getSaleGoodsList({
        outlets_id: this.outlets_id,
        goods_type: goods_type||"",
        page: 1,
        page_count: 300,
      })
      let goods_list  = data.list

      // this.total = data.total_count
      this.selectKeys = goods_list.map((el) => {
        return el.goods_id
      })

      // 组装表格所需数据
      let skuArr = []
      goods_list.map((goods) => {
        goods.is_cake = goods.goods_type==3
        goods.sku_data.map((item) => {
          let obj = goods
              obj.flavor_name = item.flavor_name
              obj.status = 1
              obj.is_cake = goods.is_cake
          if (item.specification_list.length > 0) {
            item.specification_list.map((el) => {
              obj = { ...obj, ...el }
              skuArr.push(obj)
            })
          }
        })
      })
      skuArr = rowSpan(skuArr, "goods_name")
      // skuArr = rowSpan(skuArr, "goods_id")

      this.tableData = skuArr
    },


    // 添加商品
    handlerAddGoods () {
      this.isShowAddGoods = true
    },

    // 确认添加商品 参数1：选中又被取消掉的商品，2 选中的商品
    async handlerAddGoodsOK (unRowKeys, rows) {
      
      //如果有取消掉的商品，则先把表格里有的过滤掉
      // if (unRowKeys.length > 0) {
      //   this.tableData = this.tableData.filter(
      //     (el) => !unRowKeys.includes(el.goods_id)
      //   )
      // }

      let cake_list  = rows.filter((el) => el.goods_type==3).map(gd=>{ return {goods_id: gd.goods_id} })
      let other_ids = rows.filter((el) => el.goods_type!==3).map(gd=>gd.goods_id)
      let other_sku_list = []
      if(other_ids.length){
        let { data } = await getSkuListByGoodsId({ 
          outlets_id: this.outlets_id,
          goods_ids: other_ids.join(",") 
        }, false)
        data.list.map(goods=>{
          goods.sku_data.map(flavor=>{
            flavor.specification_list.map(sku=>{
              other_sku_list.push({
                // goods_id: goods.goods_id,
                sku_id: sku.sku_id,
                max_stock: sku.max_stock || 0,
                current_stock: sku.current_stock || 0,
              })
            })
          })
        })
      }

      let that = this
      this.$confirm({
        title: "确定上架？上架后请尽快设置商品库存",
        async onOk () {
          if(cake_list.length){
            let cake_params = {
              outlets_id: that.outlets_id,
              add_goods_data: JSON.stringify(cake_list)
            }
            await editGoodsData(cake_params, true)
          }
          if(other_sku_list.length){
            let other_params = {
              outlets_id: that.outlets_id,
              update_sku_data: JSON.stringify(other_sku_list)
            }
            await editGoodsData(other_params, false)
          }
          that.isShowAddGoods = false
          if(other_sku_list.length){
            that.$message.success("已上架，请尽快设置商品库存")
          }else{
            that.$message.success("已上架")
          }
          that.initData()
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    // 批量修改最大库存
    handleMultiStock () {
      if (!this.rowSelection.selectedRowKeys) {
        this.$message.warning("请先勾选商品");
        return;
      }

      let otherSkuIds = this.tableData.filter(sku=>!sku.is_cake).map(el=>{
        return el.sku_id
      })
      let skuIds = this.rowSelection.selectedRowKeys.filter(skuid=>{
        return otherSkuIds.includes(skuid)
      })
      if (!skuIds.length) {
        this.$message.warning("蛋糕不需要设置库存");
        return;
      }

      this.isShowMultiStock = true
    },
    handleMultiStockOk () {
      let otherSkuIds = this.tableData.filter(sku=>!sku.is_cake).map(el=>{
        return el.sku_id
      })
      let skuIds = this.rowSelection.selectedRowKeys.filter(skuid=>{
        return otherSkuIds.includes(skuid)
      })

      const params = {
        outlets_id: this.outlets_id,
        update_sku_data: JSON.stringify(
          skuIds.map(skuid => {
            return {
              sku_id: skuid,
              max_stock: this.batchStock,
            }
          })
        )
      }

      editGoodsData(params, false).then((res) => {
        if (res.code === 0) {
          this.isShowMultiStock = false
          this.$message.success("操作完成")
          this.initData()
        }
      })
    },

    handlerSaveStocks(){
      // 先把操作下架的数据过滤掉
      let other_skus = this.tableData.filter((el) => !el.is_cake)

      // 如果输入了负数 那么把库存的值加到数组中 在点击提交的时候做判断
      let errStockIndex = other_skus.findIndex((item) => {
        return item.max_stock < 0
      })
      if(errStockIndex > -1){
        this.$message.error("库存不能为负数")
        return
      }

      // 如果是蛋糕判断有无新增数据，传入新增数据
      let other_sku_list = other_skus.map((item) => {
        return {
          sku_id: item.sku_id,
          max_stock: item.max_stock,
          current_stock: item.current_stock,
        }
      })
      let params = {
        outlets_id: this.outlets_id,
        update_sku_data: JSON.stringify(other_sku_list)
      }
      editGoodsData(params, false).then((res) => {
        if (res.code === 0) {
          this.isInEditStock = false
          this.$message.success("已保存")
          this.initData()
        }
      })
    },

    // 同步网点
    handlerSyncStore () {
      this.isAsyncOutlets = true
    },

    // 下架商品
    handlerRemove (row) {
      let that = this
      this.$confirm({
        title: "下架后，顾客将无法在线上小程序/官网中购买，确定下架?",
        async onOk () {
          let params = {
            outlets_id: that.outlets_id,
            off_shelves_goods_data: JSON.stringify([{
              goods_id: row.goods_id,
            }])
          }
          editGoodsData(params, row.is_cake).then((res) => {
            if (res.code === 0) {
              that.$message.success("已下架")
              that.tableData = that.tableData.filter(
                (el) => el.goods_id != row.goods_id
              )
              if(that.rowSelection){
                that.rowSelection.selectedRowKeys = []
              }
            }
          })
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    handlerEditStock () {
      this.isInEditStock = true
      this.rowSelection = this.baseRowSelection
    },
    handlerCancelEdit () {
      this.isInEditStock = false
      this.rowSelection = null
    },

    setRowClass (record) {
      if (record.status == 0) {
        return "goods-disable-status"
      } else {
        return "goods-normal-status"
      }
    },
  },
}
</script>
<style lang="less">
.goods-disable-status {
  background: rgb(205, 205, 205);
  opacity: 0.8;
  &:hover {
    background: rgb(205, 205, 205);
    opacity: 0.8;
  }
}
</style>